@import '../../baseStyles/variables.scss';

.for-foreigners-wrap {
    position: relative;
    z-index: 100;
    background-color: white;
    text-align: center;

    .cards {
        padding-bottom: 30px;
        background-color: #ffffff;
        position: relative;
        z-index: 30;
    }

    .for-foreigners-title {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 40px;
        position: relative;
        z-index: 30;
        background-color: #ffffff;
        margin: 0;
        font-weight: 400;
    }

    .for-foreigners-answers {
        height: 250px;
        background-color: $base-color;
        color: #ffffff;
        text-align: center;
        font-size: 35px;
        padding-top: 30px;
        position: relative;
        z-index: 30;
        margin-bottom: 250px;
    }

    .for-foreigners-background {
        background-image: url("../../../public/images/foreigners-background.webp");
        background-size: cover;
        background-position: 50% 50%;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0px;
        z-index: 10;
    }

    .for-foreigners-articles {
        position: relative;
        z-index: 30;
        background-color: #ffffff;
        padding: 60px 15px;
    }
}  

@media only screen and (min-width: 600px) {
    .for-foreigners-wrap {
        padding-top: 320px;
        @include page-animation;

        .for-foreigners-articles {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}

@media only screen and (min-width: 700px) {
    .for-foreigners-wrap {
        .cards {
            text-align: center;
        }

        .for-foreigners-answers {
            padding-top: 130px;
            height: 200px;
            margin-bottom: 350px;
        }
    }
}

@media only screen and (min-width: 980px) { 
    .for-foreigners-wrap {
        .for-foreigners-articles {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}

@media only screen and (min-width: 1350px) {
    .for-foreigners-wrap {
        .cards {
            padding: 0 calc((100% - 1270px)/2);
            max-width: 1270px;
            background-color: #ffffff;
        }
    }
}