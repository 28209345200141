@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');

* {
     margin: 0;
}

.app {
     font-family: 'Oswald', sans-serif;
     position: relative;
     overflow: hidden;
     color: #05155a;
}