@import '../../baseStyles/variables.scss';
  
.language-switch-dropdown {
  position: relative;
  z-index: 150;

  &.dropdown-position-on-desktop {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  &.opened {
    padding-left: calc((100vw - 170px)/2);
  }

  &.closed {
    padding-left: calc((100vw - 125px)/2);
  }

  .dropdown-button {
    width: 100px;
    height: 45px;
    border-radius: 20px;
    box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.1);
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .dropdown-icon-flag {
      border-radius: 3px;
      width: 32px;
    }

    .dropdown-icon {
      margin-left: 20px;
      width: 20px;
      height: 20px;
    }
  }

  .opened-dropdown {
    border: 1px solid $border-color;
    border-radius: 5px;
    cursor: pointer;
    width: 140px;

    .dropdown-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0;

      .dropdown-item-title {
        width: 70px;
        display: block;
        text-align: left;
      }

      .dropdown-item-img {
        width: 32px;
        border-radius: 4px;
        margin-right: 13px;
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .language-switch-dropdown {
    .dropdown-button  {
      width: 80px;
      height: 30px;

      .dropdown-icon-flag {
        width: 25px;
      }

      .dropdown-icon {
        margin-left: 10px;
        width: 15px;
        height: 15px;
      }
    }
  }
}

@media only screen and (min-width: 980px) {
  .dropdown-position-on-desktop {
    right: 100px;
  }
}