@import '../../baseStyles/variables.scss';

.home {
    width: 100%;

    .main-title-wrap {
        position: relative;
        z-index: 10;
        padding: 20px;
        background-color: $base-color;
        display: flex;
        justify-content: center;
        align-items: center;

        .main-title {
            width: 100%;
            color: white;
            text-align: center;
            font-size: 9.5vw;
            font-weight: 400;
            max-width: 95%;
            margin-bottom: 0;

            span {
                font-weight: 500;
            }
        }
    }

    .home-banner {
        background-image: url("../../../public/images/home-background.webp");
        background-size: cover;
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        background-position: 50% 50%;
    }

    .contacts {
        position: relative;
        z-index: 5;
        margin-top: 350px;
        text-align: center;
        background-color: white;
        padding-top: 30px;
    
        .title {
            font-size: 30px;
            font-weight: 400;
            padding-bottom: 30px;
        }
    
        p {
            font-size: 17px;
            font-weight: 500;
            padding-bottom: 25px;
        }
        
        .title, p {
            color: $base-color;
        }

        .social-network-icons { 
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            justify-content: center;

            img {
                width: 25px;
                height: 25px;
                margin-right: 15px;
            }

            
            .facebook-icon {
                img {
                    height: 22px;
                }
            }
        }
    }
}

@media only screen and (min-width: 500px) {
    .home {
        .main-title-wrap {
            .main-title {
                max-width: 85%;
                font-size: 37px;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    .home {
        padding-top: 320px;
        @include page-animation;

        .main-title-wrap {
            .main-title {
                max-width: 400px;
            }
        }
    }
}

@media only screen and (min-width: 900px) {
    .home {
        .main-title-wrap {
            .main-title {
                max-width: 500px;
                font-size: 45px;
            }
        }
    }
}



