@import '../../baseStyles/variables.scss';

.record-modal-wrap {
    position: fixed;
    top: calc((100% - 350px)/2);
    height: 350px;
    width: 88%;
    max-width: 400px;
    z-index: 170;
    background-color: #FFFFFF;
    margin: 0 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .record-modal-close-icon {
        position: absolute;
        right: 28px;
        top: 22px;
        width: 26px;
        height: 26px;
        cursor: pointer;

        &:before, 
        &:after {
            position: absolute;
            left: 15px;
            content: ' ';
            height: 26px;
            width: 2px;
            background-color: #000000;
        }

        &:before {
            transform: rotate(45deg);
        }
    
        &:after {
            transform: rotate(-45deg);
        }
    } 

    .record-modal-content {
        width: 80%;

        .record-modal-description {
            color: #000000;
            font-family: 'Raleway', sans-serif;
            text-align: center;
            line-height: 1.4em;
            font-size: 18px;
        }

        .record-modal-button {
            padding: 12px 0;
            text-align: center;
            font-size: 18px;
            margin-top: 40px;
            background-color: #000000;
            color: #FFFFFF;
            font-family: 'Raleway', sans-serif;
        }
    }
}

.modal-overlay {
    position: absolute;
    z-index: 160;
    background: #000000;
    opacity: .6;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

@media only screen and (min-width: 454px) { 
    .record-modal-wrap {
        margin: 0 calc((100% - 400px)/2);
    }
}

@media only screen and (min-width: 980px) { 
    .record-modal-wrap {
        max-width: 550px;
        margin: 0 calc((100% - 550px)/2);
    }
}