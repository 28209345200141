@import '../../baseStyles/variables.scss';

.contacts-wrap {
    padding: 20px 25px 60px 25px;

    .contacts-title {
        font-size: 34px;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .contacts-address,
    .contacts-email,
    .contacts-tel,
    .contacts-social-networks {
        margin-bottom: 10px;
    }

    .contacts-address,
    .contacts-tel,
    .contacts-social-networks {
        font-weight: 500;
    }

    .contacts-email {
        font-weight: 500;
    }

    .contacts-street,
    .contacts-email-address,
    .contacts-tel-numbers {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 500;
    }

    .contacts-tel-numbers {
        display: flex;
        flex-direction: column;
    }

    .contacts-social-networks-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }

        .facebook-icon {
            img {
                height: 22px;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    .contacts-wrap {
        padding-top: 320px;
        @include page-animation;
    }
}

@media only screen and (min-width: 700px) {
    .contacts-wrap {
        display: flex;
        margin-top: 50px;
        padding-left: 50px;
        padding-right: 50px;
        justify-content: space-between;

        .contacts-data {
            .contacts-data-row {
                display: flex;

                .contacts-adress-wrap,
                .contacts-email-wrap,
                .contacts-tel-wrap,
                .contacts-social-networks-wrap {
                    width: 200px;
                }
            }
        }
    }
}

@media only screen and (min-width: 980px) {
    .contacts-wrap {
        justify-content: center;

        .contacts-title {
            position: absolute;
            top: 370px;
            left: 10vw;
        }

        .contacts-data {
            .contacts-data-row {
                padding-left: 115px;

                .contacts-adress-wrap,
                .contacts-email-wrap,
                .contacts-tel-wrap,
                .contacts-social-networks-wrap {
                    margin-right: 50px;
                }
            }

            .contacts-address,
            .contacts-email,
            .contacts-tel,
            .contacts-social-networks {
                font-size: 20px;
            }

            .contacts-street,
            .contacts-email-address,
            .contacts-tel-numbers {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .contacts-wrap {
        .contacts-title {
            top: 380px;
            left: 13vw;
        }
    }
}