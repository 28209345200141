$base-color: #05155a;
$blue-color: #6d7bba;
$blue-text-color: #08208A;
$border-color: #C5BDBC;

@keyframes ani {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@mixin page-animation {
    opacity: 0;
    animation: ani 2.5s forwards;
}

