@import '../../baseStyles/variables.scss';

.card-wrap {
    max-width: 85%;
    margin: 0 auto 20px auto;
    border: 1px solid #C5BDBC;

    .card-image {
        max-width: 100%;
    }

    .card-description {
        padding: 25px 20px 25px 20px;
        color: $base-color;
        text-align: left;

        .card-description-title {
            font-size: 25px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        .card-description-translation {
            font-weight: 300;
            font-size: 18px;
            padding-bottom: 30px;
        }

        .card-description-order {
            width: 100%;
            height: 50px;
            border: 1px solid $blue-color;
            background-color: transparent;
            color: $blue-text-color;
            font-size: 17px;
            cursor: pointer;
        }
    }
}

@media only screen and (min-width: 600px) {
    .card-wrap {
        max-width: 400px;
    }
}

@media only screen and (min-width: 700px) {
    .card-wrap {
        max-width: 330px;
        margin: 10px;
        display: inline-block;

        .card-description {
            .card-description-title {
                font-size: 22px;
            }
        }
    }
}

@media only screen and (min-width: 1350px) {
    .card-wrap {
        max-width: 400px;

        .card-description {
            .card-description-title {
                font-size: 25px;
            }
        }
    }
}