@import '../../baseStyles/variables.scss';

.about-wrap {
    color: $base-color;
    text-align: center;

    .about-title {
        font-weight: 400;
        font-size: 34px;
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .about-description {
        padding: 0 15px 0 15px;

        .about-description-item {
            font-family: 'Raleway', sans-serif;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 1.8em;
            font-weight: 400;
            margin-bottom: 5px;
        }
    }
}

@media only screen and (min-width: 600px) {
    .about-wrap {
        padding-top: 320px;
        @include page-animation;
    }
}

@media only screen and (min-width: 980px) { 
    .about-wrap {
        padding-top: 390px;

        .about-title {
            width: 75%;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    
        .about-description {
            width: 75%;
            margin: 0 auto; 
    
            .about-description-item {
                font-size: 14px;
                margin-bottom: 3px;
            }
        }
    }
}

@media only screen and (min-width: 1200px) { 
    .about-wrap {
        .about-title {
            width: 55%;
        }

        .about-description {
            width: 55%;
        }
    }
}