@import '../../baseStyles/variables.scss';

.article-wrap {
    margin-bottom: 60px;
    text-align: left;
    font-family: 'Raleway', sans-serif;

    .article-title {
        color: $base-color;
        font-weight: 600;
        padding-bottom: 15px;
        font-size: 20px;

        &.article-title-bold {
            font-weight: 700;
        }

        span {
            font-weight: 800;
        }
    }

    .article-description, 
    .article-points {
        font-size: 16px;
        line-height: 1.9em;
        color: $base-color;
        font-weight: 400;
        text-align: left;
    }

    .article-description {
        .article-description-bold {
            font-weight: 700;
        }
    }
}

@media only screen and (min-width: 980px) { 
    .article-wrap {
        width: 32%;
        padding: 0 20px;

        .article-title {
            height: 80px;
        }

        .article-description, 
        .article-points {
            font-size: 17px;
        }
    }
}