@import '../../baseStyles/variables.scss';

.google-maps {
    padding-bottom: 50px;
    margin: 0 auto;
}

@media only screen and (min-width: 700px) {
    .contacts-page  {
        padding-left: 40px;
    }
} 
