@import '../../baseStyles/variables.scss';

.for-business-partners-wrap {
    .for-business-partners-banner {
        height: fit-content;
        padding-bottom: 10px;
        background-color: $base-color;

        img {
            width: 100%;
        }
    }

    .for-business-partners-data {
        color: $base-color;
        padding: 40px 20px 50px 20px;
        font-family: 'Helvetica Neue', sans-serif;

        .for-business-partners-title {
            font-size: 35px;
            font-weight: 400;
            padding-bottom: 20px;
        }

        .for-business-partners-description {
            font-size: 20px;
            letter-spacing: 1px;
            line-height: 1.6em;
            font-weight: 300;
            padding-bottom: 30px;
            
            li {
                padding-bottom: 10px;
            }
        }

        .for-business-partners-advantages {
            .advantages-title {
                font-size: 35px;
                font-weight: 400;
                padding-bottom: 50px;

                span {
                    font-weight: 900;
                }
            }

            .advantages-description {
                .advantage-item {
                    text-align: center;

                    img {
                        width: 28px;
                        height: 28px;
                        margin-bottom: 15px;
                    }

                    .description {
                        font-size: 20px;
                        letter-spacing: 1px;
                        line-height: 1.6em;
                        font-weight: 300;
                        text-align: left;
                        margin-bottom: 40px;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    .for-business-partners-wrap {
        padding-top: 320px;
        @include page-animation;

        .for-business-partners-big-banner {
            height: 500px;
            background-color: $base-color;
            position: relative;

            .banner-image {
                position: absolute;
                top: 50px;
                left: 60px;
                width: 60%;
                height: 280px;
                background-image: url("../../../public/images/clients-background.webp");
                background-size: cover;
                background-position: 50% 50%;
            }

            .banner-description {
                position: absolute;
                top: 100px;
                right: 60px;
                width: 60%;
                height: 320px;
                background-color: #ffffff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: 'Helvetica Neue', sans-serif;
                
                .for-business-partners-title {
                    font-size: 25px;
                    font-weight: 500;
                    width: 320px;
                    text-align: left;
                    padding-left: 20px;
                    margin-bottom: 10px;
                }

                .for-business-partners-description {
                    font-size: 14px;
                    font-weight: 300;
                    width: 320px;
                    line-height: 1.5em;

                    li {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 980px) { 
    .for-business-partners-wrap {
        .for-business-partners-big-banner {
            .banner-description {
                .for-business-partners-title {
                    font-size: 35px;
                    width: 500px;
                    padding-left: 15px;
                }

                .for-business-partners-description {
                    font-size: 15px;
                    width: 500px;
                    opacity: 0.9;
                }
            }
        }

        .for-business-partners-data {
            .for-business-partners-advantages {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding-bottom: 60px;

                .advantages-title {
                    width: 200px;
                    padding-top: 100px;
                    margin-right: 50px;
                }

                .advantages-description {
                    width: 650px;

                    .advantage-item {
                        display: inline-grid;
                        max-width: 300px;
                        margin-right: 25px;
                        margin-bottom: 20px;

                        .description {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px) { 
    .for-business-partners-wrap {
        .for-business-partners-data {
            .for-business-partners-advantages {
                .advantages-title {
                    margin-right: 80px;
                }

                .advantages-description {
                    width: 850px;

                    .advantage-item {
                        max-width: 400px;
                        margin-right: 25px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1300px) { 
    .for-business-partners-wrap {
        .for-business-partners-data {
            .for-business-partners-advantages {
                .advantages-title {
                    width: 300px;
                    font-size: 40px;
                }
            }
        }
    }
}