@import '../../baseStyles/variables.scss';

header {
  padding: 0 20px;
  height: 51vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 150;
  background-color: white;

  &.languageSwitchDropDown {
    align-items: center;
    justify-content: center;
    
    #brand {
      width: 80vw;
      text-align: center;
    }
  }

  #brand {
    margin-right: 20px;
    max-width: 400px;

    .tel-numbers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 10px;
      margin-top: 15px;

      p {
        font-size: 5vw;
        color: $base-color;
        font-weight: 500;
      }
    }

    img {
      max-width: 100%;
    }
  }

  #hamburger-icon {
    margin: auto 0;
    display: none;
    cursor: pointer;

    .mobile-menu {
      position: absolute;
      z-index: 100;
      top: 50px;
      left: 0;
      height: calc(100vh - 50px);
      width: 100%;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: visibility 0s, opacity 0.5s linear;
      transition: visibility 0s, opacity 0.3s linear;
    
      li {
        margin-bottom: 6px;
        font-size: 25px;
        font-weight: 400;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  
    div {
      width: 35px;
      height: 3px;
      background-color: $base-color;
      margin: 6px 0;
      transition: 0.4s;
    }

    &.open {
      .bar {
        z-index: 150;
        position: relative;
      }

      .bar1 {
        -webkit-transform: rotate(-45deg) translate(-6px, 6px);
        transform: rotate(-45deg) translate(-6px, 6px);
      }
    
      .bar2 {
        opacity: 0;
      }
    
      .bar3 {
        -webkit-transform: rotate(45deg) translate(-6px, -8px);
        transform: rotate(45deg) translate(-6px, -8px);
      }
    
      .mobile-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding-left: 0;
        visibility: visible;
        opacity: 1;

        &.mobile-menu-opened {
          justify-content: flex-start;
          padding-top: 30vw;

          li {
            opacity: 0.8;
          }
        }
      }
    } 
  }

  ul {
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  
    li {
      padding: 5px;
      margin-left: 10px;
      padding-bottom: 15px;
      font-family: 'Raleway', sans-serif;
  
      a {
        color: $base-color;
        text-decoration: none;

        &:hover,
        &.active {
          color: #0A2CBC;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    nav {
        display: none;
    }

    #hamburger-icon {
      display: block;
    }
  }
}

@media only screen and (min-width: 500px) {
  header {
    #brand {
      .tel-numbers {
        p {
          font-size: 25px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 320px;
    position: fixed;
    left: 0;
    right: 0;

    #brand {
      .tel-numbers {  
        justify-content: center;

        p {
          font-size: 18px;
          margin-bottom: 20px;

          &:first-of-type {
            margin-right: 10px;
          }
        }
      }
      img {
        max-width: 400px;
      }
    }

    ul {
      padding-left: 0;

      li {  
        padding: 1px;

        a {
         font-weight: 400;
         font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  header {
    ul {
      li {  
        padding: 5px;
      }
    }
  }
}
